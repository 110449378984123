import styled from "styled-components";


import metamask from "../assets/metamask.png";
import binance from "../assets/binance.png";
import polygon from "../assets/polygon.png";
import walletconnect from "../assets/walletconnect.png";
import coinbase from "../assets/coinbase.png";



const SponsorsContainer = styled.div`
    width: 96%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;

    > h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 4.8px;
        color: white;
        margin-bottom: 40px;
        text-align: center;

        @media screen and (max-width: 565px){
            font-size: 10px;
        }
    }

    > section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
        align-items: center;
        width: 100%;
    }
`;


const SponsorImage = styled.span`
    width: 180px;

    @media screen and (max-width: 565px){
        width: 130px;
    }

    img {
        width: 100%;
        object-fit: contain;
    }
`;


const Sponsors = () => {
    const sponsors: string[] = [
        metamask,
        binance,
        polygon,
        walletconnect,
        coinbase
    ];
    return (
        <SponsorsContainer>
            <h3>TRUSTED BY INDUSTRY LEADERS</h3>
            <section>
                {
                    sponsors.map((s, i) => (<SponsorImage key={i}><img src={s} alt="Sponsor Image" /></SponsorImage>))
                }
            </section>
        </SponsorsContainer>
    )
}



export default Sponsors;