import styled from "styled-components";

import bg from "../assets/bg2.png";


const JumbotronContainer = styled.div`
    width: 90%;
    margin-inline: auto;
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;

    .body {
        padding: 80px;
        color: var(--white);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 60%;
        justify-content: flex-start;
        gap: 30px;

        h1 {
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 48.102px; /* 109.324% */
            letter-spacing: -1.32px;
        }

        p {
            font-size: 17.524px;
            font-style: normal;
            font-weight: 400;
            line-height: 28.039px;
        }

        @media screen and (max-width: 1275px){
            width: 80%;
        }

        @media screen and (max-width: 1035px){
            width: 90%;
        }

        @media screen and (max-width: 985px){
            padding: 60px;

            p {
                font-size: 15px;
            }
        }

        @media screen and (max-width: 865px){

            h1 {
                font-size: 34px;
            }
        }

        @media screen and (max-width: 675px){
            padding: 40px;
            padding-top: 60px;

            h1 {
                font-size: 28px;
            }

            p {
                font-size: 13px;
            }
        }

        @media screen and (max-width: 535px){

            p {
                font-size: 11px;
            }
        }
        @media screen and (max-width: 445px){
            width: 95%;
            padding: 20px;
            padding-right: 0px;

            p {
                font-size: 10px;
            }
        }
    }

    .button_container {
        button {
            border-radius: 25px;
            padding: 10px 70px;
            border: none;
            cursor: pointer;
        }
    }
`;


const FooterJumbotron = () => {
    return (
        <JumbotronContainer>
            <section className="body">
                <h1>Neutral protocol caters for everything you need.</h1>
                <p>Cryptocurrency wallets provide users with a digital solution for ly storing and managing blockchain assets and cryptocurrencies. These wallets allow users to spend, receive, and trade cryptocurrencies.</p>
                <div className="button_container">
                    <button>
                       Resolve Issue
                    </button>
                </div>
            </section>
        </JumbotronContainer>
    )
}



export default FooterJumbotron;