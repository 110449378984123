import React from "react";
import styled from "styled-components";


const ButtonContainer = styled.button`
    border: none;
    font-size: ${(props: any) => props.fontSize};
    border-radius: ${(props: any) => props.radius}px;
    background-color: ${(props: any) => props.bgColor};
    outline: none;
    border: ${(props: any) => props.border};
    padding: 12px 40px;
    cursor: pointer;
    color: ${(props: any) => props.color};
    letter-spacing: 0.4px;
`;


type IButton = {
    fontSize: string
    radius: number
    bgColor?: string
    border: string
    color: string
    children: React.ReactNode
    onClick?: (e: any) => void
}


const Button = ({children, ...props}: IButton) => {
    return (
        <ButtonContainer {...props}>
            {children}
        </ButtonContainer>
    )
}


export default Button;