import styled from "styled-components";
import {FaArrowRight} from "react-icons/fa";



export type ISolutionCard = {
    title: string
    content: string
    buttonText: string
}

const SolutionCardContainer = styled.div`
    background-color: #090412;
    height: 300px;
    width: 350px;
    display: flex;
    padding: 31px 49px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    border-radius: 21px;

    > h3 {
        color: #C4A5FF;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.55px;
        text-transform: capitalize;
    }

    > p {
        color: var(--white);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 300 !important;
        line-height: 137%; /* 19.18px */
        letter-spacing: 0.35px;
        text-transform: capitalize;
    }

    > button {
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--white);
        border-radius: 11px;
        border: 1px solid rgba(171, 151, 207, 0.74);
        padding: 8px 30px 8px 15px;
        cursor: pointer;

        > span {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
`;



const SolutionCard = ({title, content, buttonText}: ISolutionCard) => {
    return (
        <SolutionCardContainer>
            <h3>{title}</h3>
            <p>{content}</p>
            <button><span>{buttonText} <FaArrowRight /></span></button>
        </SolutionCardContainer>
    )
}


export default SolutionCard;