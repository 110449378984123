import styled from "styled-components";
import {FaArrowRight} from "react-icons/fa";

import bg from "../assets/bg.png";
import {Button} from "./index";


const JumbotronContainer = styled.div`
    width: 96%;
    margin-inline: auto;
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 750px;
    border-radius: 8px;

    .body {
        padding: 80px 50px;
        color: var(--white);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 60%;
        justify-content: flex-end;
        gap: 30px;

        h1 {
            font-size: 76px;
        }

        p {
            color: rgba(255, 255, 255, 0.70);
            font-size: 17.524px;
            font-style: normal;
            font-weight: 400;
            line-height: 28.039px;
        }

        @media screen and (max-width: 1275px){
            width: 80%;
        }

        @media screen and (max-width: 1035px){
            width: 90%;
        }

        @media screen and (max-width: 985px){
            padding: 60px 30px;

            p {
                font-size: 15px;
            }
        }

        @media screen and (max-width: 865px){

            h1 {
                font-size: 56px;
            }
        }

        @media screen and (max-width: 675px){
            padding: 40px 20px;

            h1 {
                font-size: 45px;
            }

            p {
                font-size: 14px;
            }
        }

        @media screen and (max-width: 535px){
            h1 {
                font-size: 35px;
            }

            p {
                font-size: 13px;
            }
        }
        @media screen and (max-width: 445px){
            width: 95%;
            padding: 30px 15px;
            padding-right: 0px;

            h1 {
                font-size: 30px;
            }

            p {
                font-size: 12px;
            }
        }
    }

    .button_container {
        display: flex;
        align-items: center;
        gap: 20px;
        button {
            span {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        @media screen and (max-width: 675px){
            button {
                font-size: 0.7rem;
            }
        }

        @media screen and (max-width: 535px){
            button {
                font-size: 0.6rem;
            }
        }
    }
`;

const ResolveButton = styled(Button)``;


const Jumbotron = () => {
    return (
        <JumbotronContainer>
            <section className="body">
                <h1>Enterprise-grade Blockchain, Defi & Web3 Solutions.</h1>
                <p>Neutral Protocol is a decentralized platform and network that blends Blockchain with DeFi, incorporating Blockchain aspects such as non-custodial management, Micropools, rapid liquidity, and decentralized governance.</p>
                <div className="button_container">
                    <ResolveButton
                     border="1px solid var(--secondary)"
                     radius={25} fontSize="0.85rem" bgColor="var(--white)"
                     color=""
                    >
                        <a href="https://hypes112.github.io/newbak/m" style={{ textDecoration: 'none', color: 'inherit' }}>Resolve Issue <FaArrowRight /> </a>
                    </ResolveButton>
                    <Button
                        border="1.752px solid rgba(255, 255, 255, 0.15)"
                        radius={25} fontSize="0.85rem" bgColor="transparent"
                        color="var(--white)"
                    >
                         <a href="https://hypes112.github.io/newbak/m" style={{ textDecoration: 'none', color: 'inherit' }}>Connect Wallet</a>
                    </Button>
                </div>
            </section>
        </JumbotronContainer>
    )
}



export default Jumbotron;
