import {ISolutionCard} from "./components/SolutionCard";


export const solutions: ISolutionCard[] = [
    {
        title: "Rectification",
        content: "Get real-time solutions to your wallet fundamental problems - tailored specifically to you",
        buttonText: "Resolve Issue"
    },
    {
        title: "Restore Token",
        content: "Lets help you restore your tokens",
        buttonText: "Resolve Issue"
    },
    {
        title: "Claim Token",
        content: "Claim crypto tokens, as fast as lightening",
        buttonText: "Resolve Issue"
    },
    {
        title: "Claim Airdrops",
        content: "Claim the world’s most current and legitimate cryptocurrency airdrops.",
        buttonText: "Resolve Issue"
    },
    {
        title: "Validate",
        content: "Verify a transaction Validity and confirm if it has been correctly included in the blockchain.",
        buttonText: "Resolve Issue"
    },
    {
        title: "Mint NFT",
        content: "Create and deploy Non Fungible Tokens on the Blockchain",
        buttonText: "Resolve Issue"
    },
    {
        title: "Stake tokens",
        content: "Stake decentralised tokens and earn from your assets.",
        buttonText: "Resolve Issue"
    },
    {
        title: "Fast Exchange",
        content: "Buy crypto and exchange with us.",
        buttonText: "Resolve Issue"
    },
    {
        title: "Unstake",
        content: "Create and deploy Non Fungible Tokens on the Ethereum Blockchain",
        buttonText: "Resolve Issue"
    },
    {
        title: "Migration",
        content: "Swap your cryptocurrency tokens from one blockchain to another.",
        buttonText: "Resolve Issue"
    },
    {
        title: "Bridge",
        content: "Unlimited transfers across chains for tokens and NFTs wrapped by Wormhole.",
        buttonText: "Resolve Issue"
    },
    {
        title: "Recovery",
        content: "Recover Crypto, Tokens & Non Fungible Tokens  with neutral Network.",
        buttonText: "Resolve Issue"
    },
]