import {useEffect} from "react";
import styled from "styled-components";



import {Jumbotron, Nav, Sponsors, SolutionCard, FooterJumbotron} from "./components";
import { solutions } from "./data";




const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  .jumbotron {
    padding-top: 130px;
  }

  .leaders {
    margin-top: 50px;
  }

  .solutions {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 4.8px;
      color: var(--white);
      margin-bottom: 150px;
    }

    .solutions_cards {
      width: 96%;
      margin-inline: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 80px;
      grid-row-gap: 180px;
      place-items: center;

      @media screen and (max-width: 1255px){
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 80px;
      }

      @media screen and (max-width: 820px){
        grid-template-columns: 1fr;
      }
    }
  }

  .caters {
    margin-top: 200px;
    margin-bottom: 30px;
  }

  .footer {
    color: #474747;
    display: flex;
    width: 85%;
    margin-inline: auto;
    padding-block: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span {
      font-size: 17px;
      font-style: normal;
      font-weight: 300;
      line-height: 48.102px; /* 282.956% */
      letter-spacing: 0.425px;
    }
    p {
      font-size: 17px;
      font-style: normal;
      font-weight: 300;
      line-height: 48.102px; /* 282.956% */
      letter-spacing: 0.425px;
      i {
        color: #AB97CF;
        font-style: normal;
      }
    }

    b {
      font-size: 17px;
      font-style: normal;
      font-weight: 300;
      line-height: 48.102px; /* 282.956% */
      letter-spacing: 0.425px;
    }

    @media screen and (max-width: 1275px){
      width: 95%;
      justify-content: space-between;
    }

    @media screen and (max-width: 1135px){
      width: 95%;
      justify-content: space-between;

      span, p, b {
        font-size: 15px;
      }
    }

    @media screen and (max-width: 1010px){

      span, p, b {
        font-size: 13px;
      }
    }

    @media screen and (max-width: 875px){
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span, p, b {
        font-size: 15px;
      }
    }
  }
`;


type IApp = {};

const App = (props: IApp) => {

  useEffect(() => {
    console.log("App is mounted");
    return () => console.log("App is unmounted");
  }, [])
  return (
      <AppContainer>
        <Nav />
        <section className="jumbotron">
          <Jumbotron />
        </section>
        <section className="leaders">
          <Sponsors />
        </section>
        <section className="solutions">
          <h3>SOLUTIONS SUITE</h3>
          <div className="solutions_cards">
            {
              solutions.map((s, i) => <SolutionCard {...s} key={i} />)
            }
          </div>
        </section>
        <section className="caters">
          <FooterJumbotron />
        </section>
        <section className="footer">
          <span>Copyright @2022 Neutral Protocol</span>
          <p><i>Neutral Protocol Web3 Technology.</i> AB Org.nr: 559307-5988</p>
          <b>Reach out: hello@neutalprotocol.com</b>
        </section>
      </AppContainer>
  )
}



export default App;